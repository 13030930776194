
.pudu-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 8.6957vh;
  line-height: 8.6957vh;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-sizing: border-box;
  background: #fff;
  padding: 1vw 2.3913vh;
  font-size: 3.0435vh;
}

.pudu-input__prefix {
  padding-right: 5px;
}

.pudu-input__inner {
  flex: 1 auto;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: inherit;
  padding: 0;
  margin: 0;
  touch-action: manipulation;
  -webkit-appearance: none;
  overflow: visible;
  height: 100%;
}

.pudu-input__inner:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
  color: fieldtext !important;
}

.pudu-input__suffix {
  padding-left: 5px;
  cursor: pointer;
}
