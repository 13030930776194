.wg-cap-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.5);
  overflow: hidden;
}

.wg-cap-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  max-width: 300px;
  min-height: 345px;
  background: #ffffff;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 8px;
}


.wg-cap-wrap__header{
  height: 50px;
  width: 100%;
  font-size: 15px;

  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -webkit-align-items:center;
  -ms-flex-align:center;
  align-items: center;
}
.wg-cap-wrap__header span {
  padding-right: 5px;
}
.wg-cap-wrap__header span em {
  padding: 0 3px;
  font-weight: bold;
  color: #3e7cff;
  font-style: normal;
}
.wg-cap-wrap__header .wg-cap-wrap__image {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  line-height: 1;
}
.wg-cap-wrap__header .wg-cap-wrap__thumb {
  min-width: 150px;
  text-align: center;
  line-height: 1;
  max-height: 100%;
}
.wg-cap-wrap__header .wg-cap-wrap__thumb.wg-cap-wrap__hidden {
  display: none;
}

.wg-cap-wrap__body {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: #34383e;
  margin: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
.wg-cap-wrap__body .wg-cap-wrap__picture {
  position: relative;
  z-index: 10;
  width: 100%;

  /*height: 100%;*/
  /*max-width: 100%;*/
  /*max-height: 100%;*/
  /*object-fit: cover;*/
  /*text-align: center;*/
}
.wg-cap-wrap__body .wg-cap-wrap__picture.wg-cap-wrap__hidden {
  display: none;
}
.wg-cap-wrap__body .wg-cap-wrap__loading {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  width: 68px;
  height: 68px;
  margin-left: -34px;
  margin-top: -34px;
  line-height: 68px;
  text-align: center;
}
.wg-cap-wrap__body .wg-cap-wrap__dot {
  position: absolute;
  z-index: 10;
  width: 22px;
  height: 22px;
  color: #cedffe;
  background: #3e7cff;
  border: 2px solid #f7f9fb;
  line-height: 20px;
  text-align: center;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;
  cursor: default;
}

.wg-cap-wrap__footer {
  width: 100%;
  height: 40px;
  color: #34383e;
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -webkit-align-items:center;
  -ms-flex-align:center;
  align-items: center;
  padding-top: 15px;
}
.wg-cap-wrap__footer .wg-cap-wrap__ico {
  flex: 1;
}
.wg-cap-wrap__footer .wg-cap-wrap__ico img {
  width: 24px;
  height: 24px;
  color: #34383e;
  margin: 0 5px;
  cursor: pointer;
}
.wg-cap-wrap__footer .wg-cap-wrap__btn {
  width: 120px;
  height: 40px;
}
.wg-cap-wrap__footer .wg-cap-wrap__btn button {
  width: 100%;
  height: 40px;
  letter-spacing: 2px;
  text-align: center;
  padding: 9px 15px;
  font-size: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  background-color: #06f;
  border: 1px solid #06f;
  -webkit-appearance: none;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.wg-cap-wrap__footer .wg-cap-wrap__btn button:hover {
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}
