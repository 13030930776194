.pudu-onelogin {
  position: relative;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 100vh;
}

.pudu-onelogin-switcher {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.pudu-onelogin-switcher img {
  position: absolute;
  right: 0;
  top: 0;
}

