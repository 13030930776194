
.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.successImage {
  margin: 0;
  padding: 0;
  width: 50vw;
  height: 50vh;
  object-fit: contain;
}

.successText {
  font-size: 24px;
  font-weight: 500;
  color: #333;
}
