
          
.pudu-account-login-form {
  display: flex;
  flex-direction: column;
}

.pudu-account-login-form > .form-item:not(:first-child) {
  margin-top: 6.9565vh;
}


.pudu-account-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  background-color: #0066ff;
  border-color: #06f;
  text-shadow: 0 -1px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  border-radius: 2px;
  font-size: 3.0435vh;
  padding: 1vw 3.2609vh;
  cursor: pointer;
  font-weight: 400;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  touch-action: manipulation;
  -webkit-appearance: button
}

.forget-passwrod-link {
  text-align: right;
  text-decoration: none;
  margin-top: 1.7391vh;
  color: rgba(0,0,0,.4);
  font-size: 3.0435vh;
  border: none;
  background: #fff;
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
}
