.pudu-onelogin-container {
  display: flex;
  flex-direction: column;
  padding: 6vw;
  /* border: 1px solid #d4d8e0; */
  border: none;
  box-sizing: border-box;
  min-width: 348px;
  width: 100%;
  height: 100%;
}


.pudu-account-head {
  font-size: 5.2174vh;
  font-weight: 600;
  line-height: 6.9565vh;
  padding: 0;
  margin: 0;
  padding-bottom: 13.913vh;
  font-family: PingFang SC,-apple-system,BlinkMacSystemFont,Open Sans,Microsoft YaHei,Helvetica Neue,Hiragino Sans GB,WenQuanYi Micro Hei,Arial,sans-serif,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans;
}

.pudu-account-head p {
  margin: 0;
  padding: 0;
  line-height: inherit;
  font-weight: inherit;
}


